import React, { Fragment, ReactElement, Suspense } from 'react';
import styles from './emptyPageDescription.module.scss';

interface EmptyPageDescriptionProps {
  title: string;
  description: { paragraph: string | ReactElement }[];
  image: ReactElement;
  action?: ReactElement;
}

const EmptyPageDescription = ({ title, description, image, action }: EmptyPageDescriptionProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h4 className={styles.title}>{title}</h4>
          {description.map(({ paragraph }, idx) => (
            <Fragment key={String(paragraph)}>
              <div>{paragraph}</div>
              {idx !== description.length - 1 && <br />}
            </Fragment>
          ))}
        </div>
        <div className={styles.imageContainer}>
          <Suspense>{image}</Suspense>
        </div>
        <div className={styles.actionContainer}>{action}</div>
      </div>
    </div>
  );
};

export default EmptyPageDescription;
