import { ReactNode } from 'react';
import { UserRoles } from './enum/common';

export interface IUserMenuItem {
  label: string | ReactNode;
  key: string;
  link?: string;
  icon?: ReactNode;
  userAccess?: UserRoles[];
}

export type IUserMenuSettings = IUserMenuItem[];

export const LOGOUT_LABEL = 'Logout';

export const USER_MENU_SETTINGS: IUserMenuSettings = [
  {
    label: 'Personal Details',
    key: 'personalDetails',
    link: '/settings/personal-details',
    userAccess: [UserRoles.ADMIN, UserRoles.SEARCH_USER],
  },
  {
    label: 'Organization',
    key: 'organization',
    link: '/settings/organization',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Workspaces',
    key: 'workspaces',
    link: '/settings/workspaces',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Connections',
    key: 'connections',
    link: '/settings/connections',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Usage',
    key: 'usage',
    link: '/settings/usage',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Logout',
    key: 'logout',
    link: '/logout',
    userAccess: [UserRoles.ADMIN, UserRoles.SEARCH_USER],
  },
];
